import moment from "moment";
import { Paths } from "../config/paths";
import {
  AlertsConfig,
  IPermissions,
  IRolePermissions,
  ScreenPermissions,
  ScreensEnum,
} from "./types";
import { getPointerIcon } from "./MarkersHelpers";

const asyncLocalStorage = {
  setItem: function (key: string, value: string) {
    return Promise.resolve().then(function () {
      localStorage.setItem(key, value);
    });
  },
  removeItem: function (key: string) {
    return Promise.resolve().then(function () {
      localStorage.removeItem(key);
    });
  },
};

const sortBy = (key: any) => {
  return (a: any, b: any) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
};

const findIndex = (arrayElements: any, key: string, match: string | number) => {
  return arrayElements.findIndex((i: any) => i[key] === match);
};

const formatDate = (date: any) => {
  if (!date || date === "") return;
  return moment(date).format("DD/MM/YYYY");
};

const alertColor = (alertColor: string) => {
  switch (alertColor) {
    case "yellow":
      return "#FFDD00";
    case "orange":
      return "#FD7E14";
    case "red":
      return "#FF0000";
    case "green":
      return "#6BAA00";
  }
  return "";
};

const DashboardMenu = [
  ScreensEnum.ORGANIZACIONES,
  ScreensEnum.DISPOSITIVOS,
  ScreensEnum.USUARIOS,
  // ScreensEnum.ROLES, // Lo dejo comentado para poder ver en el futuro qué problemas arrastra hacer este cambio así
  ScreensEnum.COSECHAS,
  ScreensEnum.METRIC,
  ScreensEnum.REPORTE,
  ScreensEnum.RESET,
];

const getEquivalent = (path: Paths | ScreensEnum) => {
  switch (path) {
    case "/organizaciones":
    case "ORGANIZATION":
      return ScreenPermissions.ORGANIZATION;

    case "/nueva-organizacion":
    case "NEW_ORGANIZATION":
      return ScreenPermissions.ORGANIZATION;

    case "/usuarios":
    case "USERS":
      return ScreenPermissions.USER;

    case "/dispositivos":
    case "DEVICES":
      return ScreenPermissions.DEVICES;
    case "/cosechas":
    case "COSECHAS":
      return ScreenPermissions.COSECHAS;
    case "/metric":
    case "METRIC":
      return ScreenPermissions.METRIC;
    case "/reportes":
    case "REPORTS":
      return ScreenPermissions.REPORTS;
    case "/reset":
    case "RESET":
      return ScreenPermissions.RESET;

    default:
      return "NO_EQUIVALENT";
  }
};

const initialUpdateOrganization = (orgData: any) => ({
  tax_id: orgData.tax_id,
  name: orgData.name,
  legal_name: orgData.legal_name,
  phone: orgData.phone,
  email: orgData.email,
  blockchain_enabled: orgData.blockchain_enabled,
  prosegur_monitoring: orgData.prosegur_monitoring,
  street_name: orgData.street_name,
  street_number: orgData.street_number,
  floor: orgData.floor,
  apartment: orgData.apartment,
  zip_code: orgData.zip_code,
  locality_id: orgData.locality_id,
  municipality_id: orgData.municipality_id,
  country_id: orgData.country_id,
  country_l0_id: orgData.country_id,
  country_l1_id: orgData.localities && orgData.localities.country_l1_id,
  country_l2_id: orgData.localities && orgData.localities.country_l2_id,
});

const cloneWithoutReference = (elements: any) => {
  return JSON.parse(JSON.stringify(elements));
};

const getOrganizationTitle = (
  title: "company" | "device" | "countrysides" | "user",
  mode: "create" | "edit" | "view" | "link" | "delete"
) => {
  let mainTitle = "";
  let submitButton = "Guardar";
  if (mode === "view") submitButton = "Editar";

  switch (title) {
    case "company":
      mainTitle = "Nueva compañía";
      if (mode === "edit") mainTitle = "Editar compañía";
      if (mode === "view") mainTitle = "Compañía";
      break;
    case "device":
      mainTitle = "Nuevo dispositivo";
      if (mode === "link") {
        mainTitle = "Vincular dispositivo";
        submitButton = "Vincular";
      }
      if (mode === "view") mainTitle = "Dispositivo";
      if (mode === "edit") mainTitle = "Editar dispositivo";
      break;
    case "countrysides":
      mainTitle = "Nuevo establecimiento";
      if (mode === "edit") mainTitle = "Editar establecimiento";
      if (mode === "view") mainTitle = "Establecimiento";
      break;
    case "user":
      mainTitle = "Nuevo usuario";
      if (mode === "edit") mainTitle = "Editar usuario";
      if (mode === "view") mainTitle = "Usuario";
      break;
  }
  return {
    mainTitle: mainTitle,
    submitButton: submitButton,
  };
};

const convertSvgToDataImage = () => {
  const icon = getPointerIcon();
  return "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(icon);
};

const findCity = (formValues: any, orgData: any, name: string) => {
  if (
    orgData.cities &&
    orgData.cities.length > 0 &&
    orgData.localities &&
    orgData.localities.length > 0
  ) {
    const firstIndex =
      name === "countrysides"
        ? findIndex(
            orgData.localities,
            "country_l2_id",
            formValues.country_l2_id
          )
        : findIndex(orgData.localities, "id", formValues.locality_id);
    if (firstIndex !== -1) {
      const secondIndex = findIndex(
        orgData.cities,
        "id",
        orgData.localities[firstIndex].municipality_id
      );
      if (secondIndex !== -1) {
        if (name === "countrysides") {
          formValues.locality_id = orgData.localities[firstIndex].id;
        }
        formValues.municipality_id = orgData.cities[secondIndex].id;
      }
    }
  }

  return formValues;
};

const rolesPermissions = (
  values: {
    name?: string;
    id?: number;
    permissions: IPermissions[];
    role_permissions: IRolePermissions[];
  },
  formValues: any
) => {
  if (!values) return { toINACTIVE: [], toACTIVE: [], toPOST: [] };
  const permissions: { role_id?: number; permission_id: number }[] =
    formValues.permissions.map((val: number) => ({
      permission_id: val,
    }));
  const toPOST = permissions.filter(
    (x) =>
      !values.role_permissions.some((i) => i.permission_id === x.permission_id)
  );
  const toACTIVE = values.role_permissions
    .filter((x) =>
      formValues.permissions.some((i: any) => i === x.permission_id)
    )
    .map(({ permission_id, id }) => ({ id, permission_id, active: true }));
  const toINACTIVE = values.role_permissions
    .filter(
      (x) => !formValues.permissions.some((i: any) => i === x.permission_id)
    )
    .map(({ permission_id, id }) => ({ id, permission_id, active: false }));
  return { toINACTIVE, toACTIVE, toPOST };
};

const generateAlertData = (
  metric: AlertsConfig,
  metric_alert_type_id: number
) => {
  const isMOV = metric && metric.short_name === "MOV";
  const isTEMP = metric && metric.short_name === "TEMP";
  const maxPossibleValue = isMOV ? 5 : isTEMP ? 50 : 100;

  let alertVal = [
    metric && metric.min_possible_value ? metric.min_possible_value : 0,
  ];

  let metric_config = metric.metrics_alerts_config.filter(
    (ele) => ele.metric_alert_type_id === metric_alert_type_id
  );

  if (metric_config.length === 0) {
    if (!isMOV) {
      if (isTEMP) {
        alertVal.push(-10);
        alertVal.push(5);
        alertVal.push(35);
        alertVal.push(45);
      } else {
        alertVal.push(-50);
        alertVal.push(-20);
        alertVal.push(20);
        alertVal.push(50);
      }
    }
  } else {
    if (isMOV) {
      alertVal = [metric_config[0].min_value];
    } else {
      metric_config.map((ele) => {
        alertVal.push(ele.min_value);
        alertVal.push(ele.max_value);
        // eslint-disable-next-line array-callback-return
        return;
      });
    }
  }

  if (!isMOV) {
    alertVal.push(
      metric && metric.max_possible_value
        ? metric.max_possible_value
        : maxPossibleValue
    );
  }

  return alertVal;
};

const getMarkdownContent = async (readmePath: string) => {
  const response = await fetch(readmePath);
  return await response.text();
};

export {
  asyncLocalStorage,
  DashboardMenu,
  cloneWithoutReference,
  getOrganizationTitle,
  generateAlertData,
  getMarkdownContent,
  alertColor,
  findIndex,
  findCity,
  formatDate,
  getEquivalent,
  convertSvgToDataImage,
  sortBy,
  rolesPermissions,
  initialUpdateOrganization,
};
