import * as types from "./actionTypes";

const initialState = {
  harvestsList: [],
  loading: false,
  error: {
    message: "",
  },
};

const HarvestsReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  const actionType = action.type;
  const actionPayload = action.payload;
  switch (actionType) {
    case types.GET_HARVESTS_LIST_DATA:
      state = { ...state, loading: true };
      break;
    case types.GET_HARVESTS_LIST_DATA_FAIL:
      state = { ...state, loading: false, error: { message: actionPayload } };
      break;
    case types.GET_HARVESTS_LIST_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        harvestsList: actionPayload,
      };
      break;
  }

  return state;
};

export default HarvestsReducer;
