import { call, put, takeLatest } from "redux-saga/effects";
import { Endpoints } from "../../config/endpoints";
import { API_URL_IOF } from "../../config/general-config";
import { apiGet } from "../../shared/ApiService";

import {
  getMetricsConfigDataSuccess,
  getMetricsConfigDataFail,
  getMetricsTypesDataSuccess,
  getMetricsTypesDataFail,
  getMetricsZonesDataSuccess,
  getMetricsZonesDataFail,
  getMetricsSpeciesDataSuccess,
  getMetricsSpeciesDataFail,
} from "./action";

import {
  GET_METRICS_CONFIG_DATA,
  GET_METRICS_SPECIES_DATA,
  GET_METRICS_TYPES_DATA,
  GET_METRICS_ZONES_DATA,
} from "./actionTypes";

function* onGetMetricsConfigData() {
  try {
    let response: any[] = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_IOF}/${Endpoints.API_IOF.METRIC_ALERT_CONFIG}`,
        })
      );
    });

    response = response.filter((e) => !e.short_name.includes("MOV"));

    yield put(getMetricsConfigDataSuccess(response));
  } catch (error: any) {
    yield put(getMetricsConfigDataFail(error));
  }
}

function* onGetMetricsTypesData() {
  try {
    let response: any[] = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_IOF}/${Endpoints.API_IOF.METRIC_ALERT_TYPE}`,
        })
      );
    });

    response = response.filter(
      (e) => !e.name.includes("ZONA") && !e.name.includes("MOVIMIENTO")
    );

    yield put(getMetricsTypesDataSuccess(response));
  } catch (error: any) {
    yield put(getMetricsTypesDataFail(error.response));
  }
}

function* onGetMetricsZonesData() {
  try {
    let response: any[] = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_IOF}/${Endpoints.API_IOF.METRIC_ALERT_ZONES}`,
        })
      );
    });

    yield put(getMetricsZonesDataSuccess(response));
  } catch (error: any) {
    yield put(getMetricsZonesDataFail(error.response));
  }
}

function* onGetMetricsSpeciesData() {
  try {
    let response: any[] = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_IOF}/${Endpoints.API_IOF.METRIC_ALERT_SPECIES}`,
        })
      );
    });

    yield put(getMetricsSpeciesDataSuccess(response));
  } catch (error: any) {
    yield put(getMetricsSpeciesDataFail(error.response));
  }
}

function* MetricSaga() {
  yield takeLatest(GET_METRICS_CONFIG_DATA, onGetMetricsConfigData);
  yield takeLatest(GET_METRICS_TYPES_DATA, onGetMetricsTypesData);
  yield takeLatest(GET_METRICS_SPECIES_DATA, onGetMetricsSpeciesData);
  yield takeLatest(GET_METRICS_ZONES_DATA, onGetMetricsZonesData);
}

export default MetricSaga;
