// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  height: 100%;
  overflow-y: hidden;
}

body {
  background-color: #f3f2f7;
  font-family: "Poppins", sans-serif;
  height: 100%;
  margin: 0 !important;
}

body.cursor-loading {
  cursor: progress;
}

#root {
  background-color: #f3f2f7;
  height: 100%;
  overflow-y: auto;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,kCAAkC;EAClC,YAAY;EACZ,oBAAoB;AACtB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":["html {\r\n  height: 100%;\r\n  overflow-y: hidden;\r\n}\r\n\r\nbody {\r\n  background-color: #f3f2f7;\r\n  font-family: \"Poppins\", sans-serif;\r\n  height: 100%;\r\n  margin: 0 !important;\r\n}\r\n\r\nbody.cursor-loading {\r\n  cursor: progress;\r\n}\r\n\r\n#root {\r\n  background-color: #f3f2f7;\r\n  height: 100%;\r\n  overflow-y: auto;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
