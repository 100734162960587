import { call, put, takeLatest } from "redux-saga/effects";
import { Endpoints } from "../../config/endpoints";
import { API_URL_IOF } from "../../config/general-config";
import { apiGet, apiPatch, apiPut } from "../../shared/ApiService";
import { ApiResponse } from "../../shared/types";
import {
  findDeviceNetworksFail,
  findDeviceNetworksSuccess,
  findDeviceStatesFail,
  findDeviceStatesSuccess,
  findDeviceTypesFail,
  findDeviceTypesSuccess,
  getDevicesListDataFail,
  getDevicesListDataSuccess,
  getDeviceUnlinkListDataFail,
  getDeviceUnlinkListDataSuccess,
  linkOrUnlinkOrganizationFail,
  linkOrUnlinkOrganizationSuccess,
} from "./actions";
import {
  FIND_DEVICE_NETWORKS,
  FIND_DEVICE_STATES,
  FIND_DEVICE_TYPES,
  GET_DEVICES_LIST_DATA,
  GET_DEVICES_UNLINK_LIST_DATA,
  LINK_OR_UNLINK_ORGANIZATION,
} from "./actionTypes";

function* onGetDevicesListData() {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({ url: `${API_URL_IOF}/${Endpoints.API_IOF.DEVICES}` })
      );
    });
    yield put(getDevicesListDataSuccess(response));
  } catch (error) {
    yield put(getDevicesListDataFail(error));
  }
}

function* onGetDeviceUnlinkListData() {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_IOF}/${Endpoints.API_IOF.DEVICES_UNLINK}`,
        })
      );
    });
    yield put(getDeviceUnlinkListDataSuccess(response));
  } catch (error) {
    yield put(getDeviceUnlinkListDataFail(error));
  }
}

function* onLinkOrUnlinkOrganization(action: any) {
  try {
    const { organization_id, devices_id } = action.payload;

    const bodyContent: any = {
      organization_id: organization_id,
      devices_id: devices_id,
    };

    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiPut({
          url: `${API_URL_IOF}/${Endpoints.API_IOF.LINK_OR_UNLINK}`,
          body: bodyContent,
        })
      );
    });
    yield put(linkOrUnlinkOrganizationSuccess(response));
  } catch (error) {
    yield put(linkOrUnlinkOrganizationFail(error));
  }
}

function* onFindDeviceStates(action: any) {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_IOF}/${Endpoints.API_IOF.DEVICES_STATUS}`,
        })
      );
    });
    yield put(findDeviceStatesSuccess(response));
  } catch (error) {
    yield put(findDeviceStatesFail(error));
  }
}

function* onFindDeviceTypes(action: any) {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_IOF}/${Endpoints.API_IOF.DEVICES_TYPES}`,
        })
      );
    });
    yield put(findDeviceTypesSuccess(response));
  } catch (error) {
    yield put(findDeviceTypesFail(error));
  }
}

function* onFindDeviceNetworks(action: any) {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_IOF}/${Endpoints.API_IOF.DEVICES_NET_TYPES}`,
        })
      );
    });
    yield put(findDeviceNetworksSuccess(response));
  } catch (error) {
    yield put(findDeviceNetworksFail(error));
  }
}

function* DeviceSaga() {
  yield takeLatest(FIND_DEVICE_STATES, onFindDeviceStates);
  yield takeLatest(FIND_DEVICE_TYPES, onFindDeviceTypes);
  yield takeLatest(FIND_DEVICE_NETWORKS, onFindDeviceNetworks);
  yield takeLatest(GET_DEVICES_LIST_DATA, onGetDevicesListData);
  yield takeLatest(GET_DEVICES_UNLINK_LIST_DATA, onGetDeviceUnlinkListData);
  yield takeLatest(LINK_OR_UNLINK_ORGANIZATION, onLinkOrUnlinkOrganization);
}

export default DeviceSaga;
