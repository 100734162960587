import * as types from "./actionTypes";

export const getOrganizationsListDataAction = () => {
  return {
    type: types.GET_ORGANIZATIONS_LIST_DATA,
  };
};

export const getOrganizationsListDataFail = (error: any) => {
  return { type: types.GET_ORGANIZATIONS_LIST_DATA_FAIL, payload: error };
};

export const getOrganizationsListDataSuccess = (dashboardList: any) => {
  return {
    type: types.GET_ORGANIZATIONS_LIST_DATA_SUCCESS,
    payload: dashboardList,
  };
};

export const getCountryL0Action = (cleanData: boolean) => {
  return {
    type: types.GET_COUNTRY_L0,
    cleanData,
  };
};

export const getCountryL0Fail = (error: any) => {
  return { type: types.GET_COUNTRY_L0_FAIL, payload: error };
};

export const getCountryL0Success = (countryL0List: any) => {
  return {
    type: types.GET_COUNTRY_L0_SUCCESS,
    payload: countryL0List,
  };
};

export const getCountryL1Action = (countryL0Id: number | null) => {
  return {
    type: types.GET_COUNTRY_L1,
    payload: { countryL0Id },
  };
};

export const getCountryL1Fail = (error: any) => {
  return { type: types.GET_COUNTRY_L1_FAIL, payload: error };
};

export const getCountryL1Success = (countryL1List: any) => {
  return {
    type: types.GET_COUNTRY_L1_SUCCESS,
    payload: countryL1List,
  };
};

export const getCountryL2Action = (countryL1Id: number | null) => {
  return {
    type: types.GET_COUNTRY_L2,
    payload: { countryL1Id },
  };
};

export const getCountryL2Fail = (error: any) => {
  return { type: types.GET_COUNTRY_L2_FAIL, payload: error };
};

export const getCountryL2Success = (countryL2List: any) => {
  return {
    type: types.GET_COUNTRY_L2_SUCCESS,
    payload: countryL2List,
  };
};

export const getLocalitiesListDataAction = (countryL2Id: number | null) => {
  return {
    type: types.GET_LOCALITY,
    payload: { countryL2Id },
  };
};

export const getLocalitiesListDataFail = (error: any) => {
  return { type: types.GET_LOCALITY_FAIL, payload: error };
};

export const getLocalitiesListDataSuccess = (localitiesList: any) => {
  return {
    type: types.GET_LOCALITY_SUCCESS,
    payload: localitiesList,
  };
};

export const getOrganizationDataAction = (org_id: number) => {
  return {
    type: types.GET_ORGANIZATION_DATA,
    org_id,
  };
};

export const getOrganizationDataFail = (error: any) => {
  return { type: types.GET_ORGANIZATION_DATA_FAIL, payload: error };
};

export const getOrganizationDataSuccess = (organizationData: any) => {
  return {
    type: types.GET_ORGANIZATION_DATA_SUCCESS,
    payload: organizationData,
  };
};

export const cleanCreateOrUpdateResponseAction = () => {
  return { type: types.CLEAN_CREATE_OR_UPDATE_RESPONSE };
};

export const cleanCreateOrUpdateResponseFail = (error: any) => {
  return { type: types.CLEAN_CREATE_OR_UPDATE_RESPONSE_FAIL, payload: error };
};

export const cleanCreateOrUpdateResponseSuccess = () => {
  return { type: types.CLEAN_CREATE_OR_UPDATE_RESPONSE_SUCCESS };
};

export const cleanOrganizationDataAction = () => {
  return { type: types.CLEAN_ORGANIZATION_DATA };
};

export const cleanOrganizationDataFail = (error: any) => {
  return { type: types.CLEAN_ORGANIZATION_DATA_FAIL, payload: error };
};

export const cleanOrganizationDataSucess = () => {
  return { type: types.CLEAN_ORGANIZATION_DATA_SUCCESS };
};

export const clearCountrysidesLotsAction = (establishment_id?: any) => {
  return { type: types.CLEAR_LOTS_LIST, establishment_id };
};

export const clearCountrysidesLotsFail = (error: any) => {
  return { type: types.CLEAR_LOTS_LIST_FAIL, payload: error };
};

export const clearCountrysidesLotsSuccess = (payload: any) => {
  return { type: types.CLEAR_LOTS_LIST_SUCCESS, payload };
};

export const getAllCountriesAction = (establishment_id?: any) => {
  return { type: types.GET_ALL_COUNTRIES_ACTION, establishment_id };
};

export const getAllCountriesFail = (error: any) => {
  return { type: types.GET_ALL_COUNTRIES_FAIL, payload: error };
};

export const getAllCountriesSuccess = (payload: any) => {
  return { type: types.GET_ALL_COUNTRIES_SUCCESS, payload };
};
