import * as types from "./actionTypes";

const initialState = {
  metricsConfig: [],
  metricsTypes: [],
  metricsSpeciesTypes: [],
  metricsZones: [],
  display: false,
  loading: false,
  error: {
    message: "",
  },
};

const MetricReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  const actionType = action.type;
  const actionPayload = action.payload;
  switch (actionType) {
    case types.GET_METRICS_CONFIG_DATA:
      state = { ...state, loading: true };
      break;
    case types.GET_METRICS_SPECIES_DATA:
      state = { ...state, loading: true };
      break;
    case types.GET_METRICS_TYPES_DATA:
      state = { ...state, loading: true };
      break;
    case types.GET_METRICS_ZONES_DATA:
      state = { ...state, loading: true };
      break;

    case types.GET_METRICS_CONFIG_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        metricsConfig: actionPayload,
      };
      break;

    case types.GET_METRICS_SPECIES_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        metricsSpeciesTypes: actionPayload,
      };
      break;

    case types.GET_METRICS_TYPES_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        metricsTypes: actionPayload,
      };
      break;

    case types.GET_METRICS_ZONES_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        metricsZones: actionPayload,
      };
      break;

    case types.GET_METRICS_CONFIG_DATA_FAIL:
      state = { ...state, loading: false, error: { message: actionPayload } };
      break;
    case types.GET_METRICS_SPECIES_DATA_FAIL:
      state = { ...state, loading: false, error: { message: actionPayload } };
      break;
    case types.GET_METRICS_TYPES_DATA_FAIL:
      state = { ...state, loading: false, error: { message: actionPayload } };
      break;
    case types.GET_METRICS_ZONES_DATA_FAIL:
      state = { ...state, loading: false, error: { message: actionPayload } };
      break;
  }

  return state;
};

export default MetricReducer;
