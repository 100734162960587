import * as types from "./actionTypes";

export const getRolesListAction = () => {
  return { type: types.GET_ROLES_LIST };
};

export const getRolesListFail = (error: any) => {
  return { type: types.GET_ROLES_LIST_FAIL, payload: error };
};

export const getRolesListSuccess = (payload: any) => {
  return { type: types.GET_ROLES_LIST_SUCCESS, payload };
};

export const deleteRolAction = (rolId: number) => {
  return { type: types.DELETE_ROL, rolId };
};

export const deleteRolFail = (error: any) => {
  return { type: types.DELETE_ROL_FAIL, payload: error };
};

export const deleteRolSuccess = (payload: any) => {
  return { type: types.DELETE_ROL_SUCCESS, payload };
};

export const getPermissionsListAction = () => {
  return { type: types.GET_PERMISSIONS_LIST };
};

export const getPermissionsListFail = (error: any) => {
  return { type: types.GET_PERMISSIONS_LIST_FAIL, payload: error };
};

export const getPermissionsListSuccess = (payload: any) => {
  return { type: types.GET_PERMISSIONS_LIST_SUCCESS, payload };
};
