export enum Paths {
  LOGIN = "/login",
  LOGOUT = "/logout",
  NOTFOUND = "*",
  ROOT = "/",
  ORGANIZATION = "/organizaciones",
  NEW_ORGANIZATION = "/nueva-organizacion",
  DEVICES = "/dispositivos",
  NEW_DEVICE = "/nuevo-dispositivo",
  USERS = "/usuarios",
  COSECHAS = "/cosechas",
  REPORTS = "/reportes",
  NO_PERMISSIONS = "/no-permisos",
  METRIC = "/metric",
  VERIFYACCOUNT = "/verify-account",
  VERIFYRESETACCOUNT = "/verify-reset-account",
  RECOVER = "/recover-account",
  RESET = "/reset",
}
