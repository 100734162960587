import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Paths } from "../../config/paths";
import loadable from "@loadable/component";
import { LoadingScreenComponent } from "../../components/base/loadingScreen";

const fallback = {
  fallback: <LoadingScreenComponent />,
};

const DevicePage = loadable(
  () => import("../../pages/device/DevicePage"),
  fallback
);
const HarvestsPage = loadable(
  () => import("../../pages/harvests/HavestsPage"),
  fallback
);
const LoginPage = loadable(
  () => import("../../pages/login/LoginPage"),
  fallback
);
const LogoutPage = loadable(
  () => import("../../pages/logout/LogoutPage"),
  fallback
);
const MetricPage = loadable(
  () => import("../../pages/metric/MetricPage"),
  fallback
);
const NoPermissionsPage = loadable(
  () => import("../../pages/noPermissions/NoPermissions"),
  fallback
);
const NotFountPage = loadable(
  () => import("../../pages/notFound/NotFountPage"),
  fallback
);
const NewOrganizationPage = loadable(
  () => import("../../pages/organization/NewOrganizationPage"),
  fallback
);
const OrganizationPage = loadable(
  () => import("../../pages/organization/OrganizationPage"),
  fallback
);
const RecoverPage = loadable(
  () => import("../../pages/recover/RecoverPage"),
  fallback
);
const ResetPage = loadable(
  () => import("../../pages/reset/ResetPage"),
  fallback
);
const UserPage = loadable(() => import("../../pages/user/UserPage"), fallback);
const VerifyResetPage = loadable(
  () => import("../../pages/verify/VerifyResetPage"),
  fallback
);
const VerifySignupPage = loadable(
  () => import("../../pages/verify/VerifySignupPage"),
  fallback
);

export const RouterConfig = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={Paths.LOGIN} element={<LoginPage />} />
        <Route path={Paths.LOGOUT} element={<LogoutPage />} />
        <Route path={Paths.ROOT} element={<OrganizationPage />} />
        <Route path={Paths.ORGANIZATION} element={<OrganizationPage />} />
        <Route
          path={Paths.NEW_ORGANIZATION}
          element={<NewOrganizationPage />}
        />

        <Route
          path={`${Paths.NEW_ORGANIZATION}:id`}
          element={<NewOrganizationPage />}
        />
        <Route path={Paths.USERS} element={<UserPage />} />
        <Route path={Paths.DEVICES} element={<DevicePage />} />
        <Route path={Paths.REPORTS} element={<OrganizationPage />} />
        <Route path={Paths.NOTFOUND} element={<NotFountPage />} />
        <Route path={Paths.COSECHAS} element={<HarvestsPage />} />
        <Route path={Paths.NO_PERMISSIONS} element={<NoPermissionsPage />} />
        <Route path={Paths.METRIC} element={<MetricPage />} />
        <Route path={Paths.VERIFYACCOUNT} element={<VerifySignupPage />} />
        <Route path={Paths.VERIFYRESETACCOUNT} element={<VerifyResetPage />} />
        <Route path={Paths.RESET} element={<ResetPage />} />
        <Route path={Paths.RECOVER} element={<RecoverPage />} />
      </Routes>
    </BrowserRouter>
  );
};
