import ReactDOMServer from "react-dom/server";

const establishmentIcon = ReactDOMServer.renderToString(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="100"
    viewBox="18 0 100 100"
    fill="none"
  >
    <g filter="url(#a)">
      <path
        d="M69.22 10C52.006 10 38 23.953 38 41.082c-.113 25.057 30.033 45.833 31.22 46.673 0 0 31.332-21.616 31.219-46.653C100.439 23.953 86.433 10 69.219 10Z"
        fill="#1481BC"
      />
    </g>
    <path
      d="M69.507 62.193c11.387 0 20.617-9.23 20.617-20.617s-9.23-20.617-20.617-20.617c-11.386 0-20.616 9.23-20.616 20.617 0 11.386 9.23 20.617 20.616 20.617Z"
      fill="#fff"
    />
    <path
      d="M59.8 41.228V40.9c.11-.24.31-.375.543-.492 1.411-.691 2.818-1.395 4.229-2.087.134-.066.184-.139.182-.291-.008-.875-.004-1.745-.004-2.617 0-.063-.007-.126-.012-.207-.123.07-.218.131-.324.182a.584.584 0 0 1-.653-.06.579.579 0 0 1-.228-.579c.035-.226.183-.367.375-.481 1.987-1.189 3.972-2.38 5.956-3.575.283-.17.539-.174.82 0 .69.424 1.387.836 2.082 1.253l3.924 2.355c.327.199.425.56.245.86-.18.302-.546.383-.874.193l-.265-.154v.234c0 .832.01 1.663-.006 2.494-.005.23.066.341.273.442 1.407.684 2.803 1.394 4.212 2.076.48.232.534.63.408.89-.168.34-.53.425-.948.218-.064-.032-.13-.06-.206-.095v8.808h.578c.319.006.554.19.624.486.064.27-.077.546-.354.689-.029.015-.055.034-.081.052H60.25a.944.944 0 0 1-.45-.45v-.328c.24-.573.759-.432 1.21-.453v-8.802c-.069.031-.118.052-.166.075-.508.249-.758.175-1.044-.308Zm18.483 9.032c.004-.048.009-.075.009-.101 0-3.06 0-6.12.003-9.182 0-.118-.047-.164-.142-.212-1.05-.517-2.097-1.043-3.149-1.556-.307-.15-.44-.373-.437-.71.008-1.29 0-2.578.006-3.866a.258.258 0 0 0-.14-.26 929.94 929.94 0 0 1-4.014-2.409c-.11-.067-.187-.059-.294.005-1.33.801-2.662 1.6-3.997 2.394a.273.273 0 0 0-.154.273c.006 1.282-.002 2.564.007 3.845.003.358-.14.585-.46.74-1.03.5-2.05 1.015-3.079 1.518-.138.067-.193.142-.192.305.007 2.999.008 5.998.005 8.998v.215h2.495V42.4c0-.49.236-.722.73-.722h9.573c.516 0 .743.228.743.75v7.831h2.487Zm-8.94-3.675-3.355-2.933v5.868l3.354-2.935Zm5.216 2.934v-5.867l-3.352 2.934 3.352 2.933Zm-4.285-3.749 3.254-2.848H67.02l3.254 2.848Zm3.265 4.488-3.265-2.856-3.261 2.856h6.526Z"
      fill="#1481BC"
    />
    <path
      d="M70.257 39.223H69.11c-.425 0-.681-.25-.678-.67.005-.503-.026-1.013.044-1.508.13-.916.989-1.557 1.924-1.498.92.058 1.678.829 1.708 1.751.014.438.01.874 0 1.309-.006.358-.266.61-.629.615-.404.005-.813 0-1.222.001Zm-.595-1.24h1.224c0-.224.01-.438 0-.644a.612.612 0 0 0-1.22 0c-.015.207-.004.418-.004.643Z"
      fill="#1481BC"
    />
    <defs>
      <filter
        id="a"
        x=".301"
        y=".575"
        width="137.838"
        height="153.153"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation=".589" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_2_10" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4.712" />
        <feGaussianBlur stdDeviation="4.712" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
        <feBlend
          in2="effect1_dropShadow_2_10"
          result="effect2_dropShadow_2_10"
        />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="18.85" />
        <feGaussianBlur stdDeviation="14.137" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
        <feBlend
          in2="effect2_dropShadow_2_10"
          result="effect3_dropShadow_2_10"
        />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="28.274" />
        <feGaussianBlur stdDeviation="18.85" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
        <feBlend
          in2="effect3_dropShadow_2_10"
          result="effect4_dropShadow_2_10"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect4_dropShadow_2_10"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

const getPointerIcon = () => {
  return establishmentIcon;
};

export { getPointerIcon };
