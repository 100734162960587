import * as types from "./actionTypes";
import { sortBy } from "../../shared/helper";

const initialState = {
  companiesList: undefined,
  countrysidesList: undefined,
  countries: undefined,
  usersList: undefined,
  devicesList: undefined,
  lotsList: undefined,
  organizationsList: undefined,
  organizationAddressData: undefined,
  organization: undefined,
  createOrUpdateResponse: undefined,
  noOrganizationCompaniesList: undefined,
  countriesL0: [],
  countriesL1: [],
  countriesL2: [],
  provinces: [],
  localities: [],
  cities: [],
  loading: false,
  error: {
    message: "",
  },
};

const OrganizationsReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  const actionType = action.type;
  const actionPayload = action.payload;
  switch (actionType) {
    case types.CLEAN_CREATE_OR_UPDATE_RESPONSE:
    case types.CLEAN_ORGANIZATION_DATA:
    case types.GET_COUNTRY_L0:
    case types.GET_COUNTRY_L1:
    case types.GET_COUNTRY_L2:
    case types.GET_LOCALITY:
    case types.GET_ALL_COUNTRIES_ACTION:
    case types.GET_ORGANIZATION_DATA:
    case types.GET_ORGANIZATIONS_LIST_DATA:
    case types.CLEAR_LOTS_LIST:
      state = { ...state, loading: true };
      break;

    case types.GET_ORGANIZATIONS_LIST_DATA_SUCCESS:
      state = { ...state, organizationsList: actionPayload, loading: false };
      break;

    case types.CLEAR_LOTS_LIST_SUCCESS:
      state = { ...state, loading: false, lotsList: undefined };
      break;

    case types.GET_ORGANIZATION_DATA_SUCCESS:
      let countrysidesLocalList: any = [];
      let companies: any = [];
      let devices: any = [];
      let addressess: any;
      let users: any = [];
      let customOrg: any = [];

      if (actionPayload && actionPayload.length > 0) {
        actionPayload.forEach((value: any) => {
          customOrg = { ...value, ...value.addresses };
          value.companies.forEach((val: any) => {
            const { countrysides, addresses, ...rest } = val;
            const addressValues =
              addresses && addresses.locality_id
                ? {
                    locality_id: addresses.locality_id,
                    municipality_id: addresses.municipality_id,
                    street_name: addresses.street_name,
                    street_number: addresses.street_number,
                    floor: addresses.floor,
                    apartment: addresses.apartment,
                    zip_code: addresses.zip_code,
                  }
                : {};
            companies.push({ ...rest, ...addressValues });
            countrysides.forEach((value: any) => {
              countrysidesLocalList.push(value);
            });
          });
          users = value.users.concat().sort(sortBy("id"));
          devices = value.devices.concat().sort(sortBy("id"));
          addressess = value.addresses;
          countrysidesLocalList = countrysidesLocalList
            .concat()
            .sort(sortBy("id"));
          companies = companies.concat().sort(sortBy("id"));
        });
      }

      state = {
        ...state,
        usersList: users,
        countrysidesList: countrysidesLocalList,
        companiesList: companies,
        devicesList: devices,
        organization: customOrg,
        organizationAddressData: addressess,
        loading: false,
      };
      break;

    case types.GET_COUNTRY_L0_SUCCESS:
      state = {
        ...state,
        loading: false,
        countriesL0: actionPayload,
        countriesL1: actionPayload.cleanData ? [] : state.countriesL1,
      };
      break;

    case types.GET_COUNTRY_L1_SUCCESS:
      state = {
        ...state,
        loading: false,
        countriesL1: actionPayload,
        countriesL2: actionPayload.cleanData ? [] : state.countriesL2,
      };
      break;

    case types.GET_COUNTRY_L2_SUCCESS:
      state = {
        ...state,
        loading: false,
        countriesL2: actionPayload,
        localities: actionPayload.cleanData ? [] : state.localities,
      };
      break;

    case types.GET_LOCALITY_SUCCESS:
      state = {
        ...state,
        loading: false,
        localities: actionPayload,
      };
      break;

    case types.CLEAN_CREATE_OR_UPDATE_RESPONSE_SUCCESS:
      state = {
        ...state,
        createOrUpdateResponse: undefined,
        loading: false,
      };
      break;

    case types.CLEAN_ORGANIZATION_DATA_SUCCESS:
      state = {
        ...state,
        companiesList: undefined,
        countrysidesList: undefined,
        organization: undefined,
        usersList: undefined,
        devicesList: undefined,
        loading: false,
      };
      break;

    case types.GET_ALL_COUNTRIES_SUCCESS:
      state = {
        ...state,
        countries: actionPayload,
      };
      break;

    case types.CLEAN_ORGANIZATION_DATA_FAIL:
    case types.CLEAN_CREATE_OR_UPDATE_RESPONSE_FAIL:
    case types.GET_ALL_COUNTRIES_FAIL:
    case types.GET_ORGANIZATION_DATA_FAIL:
    case types.GET_ORGANIZATIONS_LIST_DATA_FAIL:
    case types.CLEAR_LOTS_LIST_FAIL:
      state = { ...state, loading: false, error: { message: actionPayload } };
      break;
  }

  return state;
};

export default OrganizationsReducer;
