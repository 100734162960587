import { combineReducers } from "redux";
import UserReducer from "./users/reducer";
import OrganizationsReducer from "./organizations/reducer";
import DeviceReducer from "./devices/reducer";
import RolesReducer from "./roles/reducer";
import HarvestsReducer from "./harvests/reducer";
import MetricReducer from "./metric/reducer";
import LanguageReducer from "./language/reducer";

const rootReducer = combineReducers({
  userData: UserReducer,
  organizationsData: OrganizationsReducer,
  rolesData: RolesReducer,
  deviceData: DeviceReducer,
  harvestsData: HarvestsReducer,
  languageData: LanguageReducer,
  metricsData: MetricReducer,
});

export default rootReducer;
