import React from "react";
import { Box, CircularProgress } from "@mui/material";

export const LoadingScreenComponent = () => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "calc(50vh - 60px)",
      }}
    >
      <CircularProgress
        style={{ width: 64, height: 64 }}
        className="color-green"
      />
      <h3 style={{ marginTop: 10 }}>Cargando</h3>
      <p style={{ marginTop: 0 }}>
        Esta pantalla de carga solo se muestra la primera vez que entra a la web
        o a una nueva sección de la misma.
      </p>
    </Box>
  );
};
