import { CSSProperties } from "react";

export interface BaseApiParams {
  url: string;
  unauthorizedCallback?: any;
  showSuccesMessage?: boolean;
}

export interface GetParams extends BaseApiParams {}

export interface DeleteParams extends BaseApiParams {
  body?: any;
}

export interface PostParams extends BaseApiParams {
  body: any;
  getRepresentation?: boolean;
}

export interface PutParams extends BaseApiParams {
  body: any;
}

export interface PostDownloadParams extends PostParams {
  fileName: string;
  extension: string;
}

export interface ReprocessData {
  method: "POST" | "PATCH" | "GET" | "DELETE";
  body: any;
  url: string;
}

export enum ConfirmDialogTypeEnum {
  Info = "Info",
  Exito = "Exito",
  Advertencia = "Advertencia",
  Eliminar = "Eliminar",
  Error = "Error",
}

export enum ScreensEnum {
  ORGANIZACIONES = "ORGANIZATION",
  NUEVA_ORGANIZACION = "NEW_ORGANIZATION",
  DISPOSITIVOS = "DEVICES",
  NUEVO_DISPOSITIVO = "NEW_DEVICE",
  USUARIOS = "USERS",
  COSECHAS = "COSECHAS",
  METRIC = "METRIC",
  REPORTE = "REPORTS",
  RESET = "RESET",
}

export enum ScreenPermissions {
  ORGANIZATION = "Organizaciones",
  COMPANY = "Compañías",
  ESTABLISHMENT = "Establecimientos",
  DEVICES = "Dispositivos",
  USER = "Usuarios",
  COSECHAS = "Cosechas",
  METRIC = "Métricas alertas",
  USER_PLATFORM = "Usuarios plataforma",
  SILO = "Silos",
  REPORTS = "Reportes",
  RESET = "Cambiar Contraseña",
}

export type AuthenticatedRouteProps = {
  path: string;
  children: any;
  login?: boolean;
  withHeader?: boolean;
  dashboard?: boolean;
  withLogo?: boolean;
  withDashboardLogo?: boolean;
  headerNavigationName?: string;
  goBack?: () => void;
  withPadding?: boolean;
  handleScreenChange?: any;
  currentScreen?: ScreensEnum;
  setCurentScreen?: any;
  mainTitle?: string;
  withMetricSave?: boolean;
  metricHandle?: any;
};

export interface ReduxBase {
  loading: boolean;
  error: {
    message: string;
  };
}

export interface ReduxUserData extends ReduxBase {
  user: any;
  users: any[];
  usersList: any[];
  userProfile: any;
  userReset: any;
  userCreateOrUpdateResponse: any;
}

export interface ReduxDashboardData extends ReduxBase {
  currentDashboardOrg: DashboardStatsProps;
}

export interface ReduxOrganizationsData extends ReduxBase {
  countriesL0: any[];
  countriesL1: any[];
  countriesL2: any[];
  cities: any[];
  provinces: any[];
  localities: any[];
  companiesList: any[];
  lotsList: ILots[];
  usersList: any[];
  countrysidesList: any[];
  countries: any[];
  devicesList: any[];
  organization: any;
  organizationsList: any[];
  organizationAddressData: any;
  noOrganizationCompaniesList: any[];
  createOrUpdateResponse: any;
}

export interface ReduxRolesData extends ReduxBase {
  rolesList: any[];
  permissionsList: any[];
}

export interface ReduxDeviceData extends ReduxBase {
  devicesList: any[];
  device: any;
  devicesUnlink: any[];
  states: any[];
  types: any[];
  networks: any[];
}

export interface ReduxLanguageData extends ReduxBase {
  language: string;
}

export interface ReduxHarvestsData extends ReduxBase {
  harvestsList: any[];
}

export interface ReduxMetricData extends ReduxBase {
  metricsConfig: AlertsConfig[];
  metricsTypes: MetricAlertTypes[];
  metricsSpeciesTypes: any[];
  metricsZones: any[];
  display: boolean;
}

export type ReduxType = {
  userData: ReduxUserData;
  organizationsData: ReduxOrganizationsData;
  rolesData: ReduxRolesData;
  deviceData: ReduxDeviceData;
  languageData: ReduxLanguageData;
  harvestsData: ReduxHarvestsData;
  metricsData: ReduxMetricData;
};

export interface NavBarProps {
  path: string;
}

export interface HeaderProps {
  width: number;
  withLogo?: boolean;
  withDashboardLogo?: boolean;
  dashboard?: boolean;
  login?: boolean;
  collapsed: boolean;
  headerNavigationName?: string;
  toggleCollapsed: () => void;
  goBack?: () => void;
}

export interface ModalProps {
  open: boolean;
  formValues?: any;
  name?: "company" | "device" | "countrysides" | "user";
  onSubmit?: (...args: any) => Promise<void>;
  title?: string;
  submitName?: string;
}

export interface UserLoginRequest {
  username: string | undefined;
  password: string | undefined;
}

export interface UserVerifyAccountRequest {
  type: "signup" | "recovery" | "magiclink";
  email: string | undefined;
  token: string | undefined;
}

export interface ToastProps {
  bodyContent: any;
  showToast: boolean;
  errorType: boolean;
  toggleShow: () => void;
}

export interface ApiResponseAuth {
  msg: string;
  token: string;
  user: {
    id: number;
    username: string;
    fullname: string;
    email: string;
    phone: string;
    national_id: string;
    role_id: number;
    organization_id: number;
    active: boolean;
    created_at: Date;
    updated_at: Date;
    banned: boolean;
    password: string;
    verification_code: number;
    verification_code_sent_at: Date;
    last_verification_at: Date;
    issuperadmin: boolean;
    roles: {
      id: number;
      name: string;
      active: boolean;
      created_at: Date;
      updated_at: Date;
      role_permissions: [
        {
          id: number;
          role_id: number;
          permission_id: number;
          active: boolean;
          created_at: Date;
          updated_at: Date;
          permissions: {
            id: number;
            name: string;
            active: boolean;
            created_at: Date;
            updated_at: Date;
          };
        }
      ];
    };
    user_permissions: any[];
  };
}

export interface ApiResponse {
  error: boolean;
  code: number;
  message: string;
  data: any;
  info: {
    time: number;
    sequence: number;
  };
}

export interface OrganizationProps {
  user_id: number;
  organization_id: number;
  organization_name: string;
}

export interface DashboardStatsProps {
  establishment_data: DashboardEstablishmentStatsProps[];
  device_data: DashboardDeviceStatsProps[];
  silo_data: DashboardSiloStatsProps[];
  siloCount: number;
  tonsCount: number;
  alertsCount: number;
  monitored_tons_crop_data: MonitoredTonsCrop[];
  monitored_tons_establishment_data: MonitoredTonsEstablishment[];
}

export interface DashboardEstablishmentStatsProps {
  LOTE_NOMBRE: string;
  CANTIDAD: number;
  PRIMER_SILO_LATITUD: string;
  PRIMER_SILO_LONGITUD: string;
}

export interface DashboardDeviceStatsProps {
  DISPOSITIVO_TIPO: string;
  CANTIDAD: number;
}

export interface DashboardSiloStatsProps {
  ELEMENTO_CANTIDAD_DISPOSITIVO: number;
  ELEMENTO_NOMBRE: string;
  ELEMENTO_PRODUCTO: string;
  CAMPO_NOMBRE: string;
  LOTE_NOMBRE: string;
  ALERTA_COLOR: string;
  ALERTA_NUMERO: number;
  Indicador: string;
  Valor_Alerta: number;
  PESO: number;
}

export interface CurrentEstablishment {
  ORGANIZACION_ID: number;
  LOTE_ID: number;
  CAMPO_NOMBRE: string;
  LOTE_NOMBRE: string;
  LOTE_PERIMETRO: any;
  ELEMENTO_ID: number;
  ELEMENTO_NOMBRE: string;
  ELEMENTO_PRODUCTO: string;
  ELEMENTO_COSECHA: string;
  ELEMENTO_TIPO: string;
  ELEMENTO_CANTIDAD_DISPOSITIVO: number;
  ELEMENTO_LATITUD: string;
  ELEMENTO_LONGITUD: string;
  PESO: any;
}

export interface InfoWindow {
  SILO_ID: number;
  DISPOSITIVO_ID: number;
  DISPOSITIVO_NOMBRE: string;
  DISPOSITIVO_TIPO: string;
  DISPOSITIVO_ESTADO: string;
  "MAX(FECHA)": Date;
  ID_DISPOSITIVO: number;
  ALERTA_COLOR: string;
  ALERTA_NUMERO: number;
}

export interface InfoWindowTooltipProps {
  marker: CurrentEstablishment;
  mapInfo?: InfoWindow;
}

export interface MonitoredTonsCrop {
  ELEMENTO_PRODUCTO: string;
  PESO: number;
}

export interface MonitoredTonsEstablishment {
  CAMPO_NOMBRE: string;
  PESO: number;
}

export interface Alert {
  ORGANIZACION: number;
  ID_ALERTA: number;
  ALERTA_COLOR: string;
  ALERTA_NUMERO: number;
  FECHA: Date;
  TipoAlerta: string;
  Establecimiento: string;
  Lote: string;
  ID_SILO: number;
  Silobolsa: string;
  Valor_Alerta: string;
  DISPOSITIVO: string;
  Indicador: string;
}

export interface CustomLinkProps {
  label: string;
  type: "SIMPLE" | "SELECT" | "EXCELUPLOAD";
  id: string | number;
  disabled?: boolean;
  title?: string;
  variant?: "text" | "outlined" | "contained";
  startAdornment?: boolean;
  style?: CSSProperties;
  className?: string;
  select?: boolean;
  items?: any[];
  onClick?: (...args: any) => void;
  onChange?: (...args: any) => void;
}

export interface ArrowButtonProps {
  onClick: () => void;
  disabled: boolean;
  className?: string;
}

export interface FormGridProps {
  formValues: any;
  onChange: ({ value, name }: { value: any; name: string }) => void;
  createMode?: boolean;
  editionMode?: boolean;
  linkMode?: boolean;
  viewMode?: boolean;
  onSubmit?: (...args: any) => Promise<void>;
  organizationId?: number;
  selectedFromOrgRows?: any[];
  setSelectedFromOrgRows?: any;
}

export interface ModalFormHandlerProps extends FormGridProps {
  name?: "company" | "device" | "countrysides" | "user";
  handlePermissionDelete: (id: number) => Promise<void>;
  verifyDuplicateLotsModal: (lotsList: any[], loteValues: boolean) => void;
  handleLotsChange: (
    method: "ADD" | "DELETE" | "UPDATE",
    values: any,
    initialValues: any
  ) => void;
  setToastMessages: any;
  setShowToast: any;
}

export interface GridColumns {
  id: string;
  width?: number | string;
  render?: (value: any, record: any) => JSX.Element;
  align?: string;
  label?: string;
}

export interface DeviceColumnProps {
  id:
    | "checkbox"
    | "id"
    | "name"
    | "type"
    | "lance_number"
    | "eui_id"
    | "version"
    | "organization_id"
    | "last_measurement"
    | "device_statuses"
    | "network_server"
    | "created_at"
    | "acciones";  
  label: string;
  align?: "center" | "right";
  width?: string | number;
  idValue?: string;
  applyFormat?: boolean;
  showOrgName?: boolean;
  render?: (data: any, record: any) => JSX.Element;
  format?: any;
}

export interface Countryside {
  organization_id: number;
  organization_name: string;
  company_id: number;
  company_name: string;
  countryside_id: number;
  countryside_name: string;
  countryside_lat: string;
  countryside_lon: string;
  count_silobags: number;
  first_silobag_lat: number;
  first_silobag_lon: number;
}

export interface CurrentCountryside {
  silobag_id: number;
  silobag_name: string;
  silobag_product_type: string;
  silobag_species_type: string;
  silobag_harvest: string;
  silobag_lat: any;
  silobag_lon: any;
  lot_id: number;
  lot_name: string;
  lot_geom: null;
  countryside_name: string;
  countryside_id: number;
  count_devices: number;
  highest_metric_id: number;
  color_id: number;
  color_name: string;
  color_hex: string;
  count_alerts: number;
}

export interface RolesPermissionsPATCH {
  toACTIVE: { id: number }[];
  toINACTIVE: { id: number }[];
  toPOST: {
    role_id?: number | undefined;
    permission_id: number;
  }[];
}
export interface IPermissions {
  id: number;
  active: boolean;
  name: string;
  role_permissions?: {
    active: boolean;
    id: number;
    role_permissions: { id: number };
  };
}

export interface IRolePermissions {
  id: number;
  permission_id: number;
}

// ALERTS TYPES
export interface MetricAlertTypes {
  id: number;
  name: string;
  active: boolean;
  created_at: Date;
  updated_at?: any;
}

export interface Colors {
  id: number;
  name: string;
  hex: string;
  active: boolean;
  created_at: Date;
  updated_at?: any;
}

export interface BaseMetricProps {
  id: number;
  name: string;
  active: boolean;
  created_at: Date;
  updated_at?: any;
}

export interface SilobagSpeciesTypes extends BaseMetricProps {}
export interface MetricZones extends BaseMetricProps {}
export interface MetricAlertTypes extends BaseMetricProps {}

export interface MetricsAlertsConfig {
  id: number;
  metric_id: number;
  metric_alert_type_id: number;
  color_id: number;
  metric_zone_id: number;
  silobag_species_type_id: number;
  sensitivity_level: number;
  min_value: number;
  max_value: number;
  organization_id: number;
  active: boolean;
  created_at: Date;
  updated_at?: any;
  metric_alert_types: MetricAlertTypes;
  colors: Colors;
  silobag_species_types: SilobagSpeciesTypes;
  metric_zones: MetricZones;
}

export interface AlertsConfig {
  id: number;
  name: string;
  short_name: string;
  min_possible_value: number;
  max_possible_value: number;
  active: boolean;
  created_at: Date;
  updated_at: Date;
  metrics_alerts_config: MetricsAlertsConfig[];
}

export interface ILots {
  active: boolean;
  country_l2_id: number | null;
  countryside_id: number;
  updated_at: string | null;
  geom: IGeom | null;
  id: number;
  name: string;
}

// DATA TYPES
export interface IGeom {
  coordinates: number[][];
  type: string;
}
