import * as types from "./actionTypes";

type State = {
  language: string;
};

const initialState: State = {
  language: "es",
};

const LanguageReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  const actionType = action.type;
  const actionPayload = action.payload;
  switch (actionType) {
    case types.SET_LANGUAGE_ACTION: {
      return { ...state, language: actionPayload };
    }
    default:
      return state;
  }
};

export default LanguageReducer;
