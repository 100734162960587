export const Endpoints = {
  API_IOF: {
    AUTH: "login",
    COUNTRIES_L0: "countries_l0",
    COUNTRIES_L1: "countries_l1",
    COUNTRIES_L2: "countries_l2",
    CITY: "municipalities",
    COMPANIES: "companies",
    COUNTRYSIDES: "countrysides",
    COUNTRIES: "countries_l0",
    DEVICES: "devices",
    DEVICES_STATUS: "devicestates",
    DEVICES_TYPES: "devicesTypes",
    DEVICES_NET_TYPES: "deviceNetTypes",
    DEVICES_UNLINK: "devices/unorganized",
    HARVESTS: "harvests",
    LOCALITIES: "localities",
    LOTS: "lots",
    LINK_OR_UNLINK: "devices/update/organizations",
    MASSIVE_UPLOAD: "devices/massive_upload",
    METRIC_ALERT_CONFIG: "metricsAlertConfig",
    METRIC_ALERT_TYPE: "alertActionsTypes",
    METRIC_ALERT_ZONES: "metricAlertZones",
    METRIC_ALERT_SPECIES: "silobagsSpeciesTypes",
    ORGANIZATIONS: "organizations",
    PERMISSIONS: "permissions",
    RECOVER: "recover",
    USERS: "users",
    USER_PERMISSIONS: "users_permissions",
    USER_COUNTRYSIDES_PERMISSIONS: "user_countrysides_permissions",
    SILO_PERMISSION: "silobags_permissions",
    VERIFY: "verify",
  },
};
