import * as types from "./actionTypes";

const initialState = {
  rolesList: undefined,
  permissionsList: undefined,
  loading: false,
  error: {
    message: "",
  },
};

const RolesReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  const actionType = action.type;
  const actionPayload = action.payload;
  switch (actionType) {
    case types.GET_ROLES_LIST:
      state = { ...state, loading: true };
      break;
    case types.GET_ROLES_LIST_FAIL:
      state = { ...state, loading: false, error: { message: actionPayload } };
      break;
    case types.GET_ROLES_LIST_SUCCESS:
      const formattedRoles = actionPayload.map((val: any) => {
        const permissions = val.role_permissions.map((value: any) => {
          return {
            name: value.permissions.name,
            active: value.permissions.active,
            id: value.permissions.id,
          };
        });
        return { ...val, permissions };
      });

      state = { ...state, loading: false, rolesList: formattedRoles };
      break;
    case types.CREATE_ROL:
      state = { ...state, loading: true };
      break;
    case types.CREATE_ROL_FAIL:
      state = { ...state, loading: false, error: { message: actionPayload } };
      break;
    case types.CREATE_ROL_SUCCESS:
      state = { ...state, loading: false };
      break;
    case types.UPDATE_ROL:
      state = { ...state, loading: true };
      break;
    case types.UPDATE_ROL_FAIL:
      state = { ...state, loading: false, error: { message: actionPayload } };
      break;
    case types.UPDATE_ROL_SUCCESS:
      state = { ...state, loading: false };
      break;
    case types.DELETE_ROL:
      state = { ...state, loading: true };
      break;
    case types.DELETE_ROL_FAIL:
      state = { ...state, loading: false, error: { message: actionPayload } };
      break;
    case types.DELETE_ROL_SUCCESS:
      state = { ...state, loading: false };
      break;
    case types.GET_PERMISSIONS_LIST:
      state = { ...state, loading: true };
      break;
    case types.GET_PERMISSIONS_LIST_FAIL:
      state = { ...state, loading: false, error: { message: actionPayload } };
      break;
    case types.GET_PERMISSIONS_LIST_SUCCESS:
      state = { ...state, loading: false, permissionsList: actionPayload };
      break;
  }

  return state;
};

export default RolesReducer;
