export const GET_METRICS_CONFIG_DATA = "GET_METRICS_CONFIG_DATA";
export const GET_METRICS_CONFIG_DATA_FAIL = "GET_METRICS_CONFIG_DATA_FAIL";
export const GET_METRICS_CONFIG_DATA_SUCCESS =
  "GET_METRICS_CONFIG_DATA_SUCCESS";

export const GET_METRICS_TYPES_DATA = "GET_METRICS_TYPES_DATA";
export const GET_METRICS_TYPES_DATA_FAIL = "GET_METRICS_TYPES_DATA_FAIL";
export const GET_METRICS_TYPES_DATA_SUCCESS = "GET_METRICS_TYPES_DATA_SUCCESS";

export const GET_METRICS_ZONES_DATA = "GET_METRICS_ZONES_DATA";
export const GET_METRICS_ZONES_DATA_FAIL = "GET_METRICS_ZONES_DATA_FAIL";
export const GET_METRICS_ZONES_DATA_SUCCESS = "GET_METRICS_ZONES_DATA_SUCCESS";

export const GET_METRICS_SPECIES_DATA = "GET_METRICS_SPECIES_DATA";
export const GET_METRICS_SPECIES_DATA_FAIL = "GET_METRICS_SPECIES_DATA_FAIL";
export const GET_METRICS_SPECIES_DATA_SUCCESS =
  "GET_METRICS_SPECIES_DATA_SUCCESS";

export const SET_METRICS_DISPLAY = "SET_METRICS_DISPLAY";
