import { all, fork } from "redux-saga/effects";
import UserSaga from "./users/saga";
import OrganizationsSaga from "./organizations/saga";
import DeviceSaga from "./devices/saga";
import RolesSaga from "./roles/saga";
import HarvestsSaga from "./harvests/saga";
import MetricSaga from "./metric/saga";

export default function* rootSaga() {
  yield all([fork(UserSaga)]);
  yield all([fork(OrganizationsSaga)]);
  yield all([fork(DeviceSaga)]);
  yield all([fork(RolesSaga)]);
  yield all([fork(HarvestsSaga)]);
  yield all([fork(MetricSaga)]);
}
