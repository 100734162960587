export const GET_ORGANIZATIONS_LIST_DATA = "GET_ORGANIZATIONS_LIST_DATA";
export const GET_ORGANIZATIONS_LIST_DATA_FAIL =
  "GET_ORGANIZATIONS_LIST_DATA_FAIL";
export const GET_ORGANIZATIONS_LIST_DATA_SUCCESS =
  "GET_ORGANIZATIONS_LIST_DATA_SUCCESS";

export const GET_ORGANIZATION_DATA = "GET_ORGANIZATION_DATA";
export const GET_ORGANIZATION_DATA_FAIL = "GET_ORGANIZATION_DATA_FAIL";
export const GET_ORGANIZATION_DATA_SUCCESS = "GET_ORGANIZATION_DATA_SUCCESS";

export const CLEAN_CREATE_OR_UPDATE_RESPONSE =
  "CLEAN_CREATE_OR_UPDATE_RESPONSE";
export const CLEAN_CREATE_OR_UPDATE_RESPONSE_FAIL =
  "CLEAN_CREATE_OR_UPDATE_RESPONSE_FAIL";
export const CLEAN_CREATE_OR_UPDATE_RESPONSE_SUCCESS =
  "CLEAN_CREATE_OR_UPDATE_RESPONSE_SUCCESS";

export const GET_COUNTRY_L0 = "GET_COUNTRY_L0";
export const GET_COUNTRY_L0_FAIL = "GET_COUNTRY_L0_FAIL";
export const GET_COUNTRY_L0_SUCCESS = "GET_COUNTRY_L0_SUCCESS";

export const GET_COUNTRY_L1 = "GET_COUNTRY_L1";
export const GET_COUNTRY_L1_FAIL = "GET_COUNTRY_L1_FAIL";
export const GET_COUNTRY_L1_SUCCESS = "GET_COUNTRY_L1_SUCCESS";

export const GET_COUNTRY_L2 = "GET_COUNTRY_L2";
export const GET_COUNTRY_L2_FAIL = "GET_COUNTRY_L2_FAIL";
export const GET_COUNTRY_L2_SUCCESS = "GET_COUNTRY_L2_SUCCESS";

export const GET_LOCALITY = "GET_LOCALITY";
export const GET_LOCALITY_FAIL = "GET_LOCALITY_FAIL";
export const GET_LOCALITY_SUCCESS = "GET_LOCALITY_SUCCESS";

export const CLEAN_ORGANIZATION_DATA = "CLEAN_ORGANIZATION_DATA";
export const CLEAN_ORGANIZATION_DATA_FAIL = "CLEAN_ORGANIZATION_DATA_FAIL";
export const CLEAN_ORGANIZATION_DATA_SUCCESS =
  "CLEAN_ORGANIZATION_DATA_SUCCESS";

export const CLEAR_LOTS_LIST = "CLEAR_LOTS_LIST";
export const CLEAR_LOTS_LIST_FAIL = "CLEAR_LOTS_LIST_FAIL";
export const CLEAR_LOTS_LIST_SUCCESS = "CLEAR_LOTS_LIST_SUCCESS";

export const GET_ALL_COUNTRIES_ACTION = "GET_ALL_COUNTRIES_ACTION";
export const GET_ALL_COUNTRIES_FAIL = "GET_ALL_COUNTRIES_FAIL";
export const GET_ALL_COUNTRIES_SUCCESS = "GET_ALL_COUNTRIES_SUCCESS";
