import FileSaver from "file-saver";

import { getToken, unauthorizedCallback } from "../core/auth/AuthService";

import {
  BaseApiParams,
  DeleteParams,
  GetParams,
  PostDownloadParams,
  PostParams,
  PutParams,
  ReprocessData,
} from "./types";

const genericRequest = async (
  server: string,
  endpoint: string,
  method: "POST" | "GET" | "PATCH" | "DELETE",
  body: any,
  unauthorizedCallback?: any
) => {
  const url = `${server}/${endpoint}`;
  switch (method) {
    case "POST":
      return apiPost({ url, body, unauthorizedCallback });
    case "DELETE":
      return apiDelete({ url, showSuccesMessage: true, unauthorizedCallback });
    default:
      return apiGet({ url, unauthorizedCallback });
  }
};

const apiGet = async (params: GetParams) => {
  try {
    const { url } = params;
    const response = await fetch(url, {
      method: "GET",
      headers: getHeaders(),
    });

    return processJsonResponse(params, response, {
      method: "GET",
      url: url,
    });
  } catch (error) {
    console.error({ error });
  }
};

const apiDelete = async (params: DeleteParams) => {
  try {
    const { url } = params;
    const response = await fetch(url, {
      method: "DELETE",
      headers: getHeaders(true),
    });

    return processJsonResponse(params, response, {
      method: "DELETE",
      body: params.body,
      url: url,
    });
  } catch (error) {
    console.error({ error });
  }
};

const apiPost = async (params: PostParams) => {
  try {
    const { url, getRepresentation } = params;
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(params.body),
      headers: getHeaders(getRepresentation),
    });
    return processJsonResponse(params, response, {
      method: "POST",
      body: params.body,
      url: url,
    });
  } catch (error) {
    console.error({ error });
  }
};

const apiPut = async (params: PutParams, stringify = true) => {
  const { url } = params;
  const response = await fetch(url, {
    method: "PUT",
    body: stringify ? JSON.stringify(params.body) : params.body,
    headers: getHeaders(),
  });

  return processJsonResponse(params, response, {
    method: "PUT",
    body: params.body,
    url: url,
  });
};

const apiPatch = async (params: PutParams, stringify = true) => {
  const { url } = params;
  const response = await fetch(url, {
    method: "PATCH",
    body: stringify ? JSON.stringify(params.body) : params.body,
    headers: getHeaders(true),
  });

  return processJsonResponse(params, response, {
    method: "PATCH",
    body: params.body,
    url: url,
  });
};

const apiPostDownload = async (params: PostDownloadParams) => {
  try {
    const { url } = params;

    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(params.body),
      headers: getHeaders(),
    });

    return processFileResponse(params, response, {
      method: "POST",
      body: params.body,
      url: url,
    });
  } catch (error) {
    console.error({ error });
  }
};

const getHeaders = (getRepresentation: boolean = false) => {
  const jwtToken = getToken();
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Prefer", `return=representation`);
  headers.append("Access-Control-Allow-Origin", "http://localhost:3000");
  if (jwtToken) {
    headers.append("Authorization", `Bearer ${jwtToken}`);
  }
  return headers;
};

const getHeadersWithoutContentType = () => {
  const jwtToken = getToken();
  const headers = new Headers();
  if (jwtToken) {
    headers.append("Authorization", `Bearer ${jwtToken}`);
  }

  return headers;
};

const processJsonResponse = async (
  params: BaseApiParams,
  response: Response,
  reprocessData: any
) => {
  const contentType = response.headers.get("content-type");
  if (
    (response.status === 200 || response.status === 204) &&
    params.showSuccesMessage
  ) {
    //message.success("Accion realizada correctamente!", 8);
  }

  if (
    response.status === 200 &&
    contentType?.indexOf("application/json") !== -1
  ) {
    return await response.json();
  } else if (response.status === 200) {
    return await response.text();
  } else if (response.status === 201) {
    const r: any = await response.text();
    return JSON.parse(r);
  }

  return await processResponse(params, response, reprocessData);
};

const processFileResponse = async (
  params: PostDownloadParams,
  response: Response,
  reprocessData: any
) => {
  const { fileName, extension } = params;

  if (response.status === 200) {
    const blob = await response.blob();

    FileSaver.saveAs(blob, `${fileName.toLocaleLowerCase()}.${extension}`);
  }

  return await processResponse(params, response, reprocessData);
};

const getErrorFromServiceResponse = async (response: Response) => {
  const objectResponse = await response.json();
  if (objectResponse && objectResponse.error_description)
    return objectResponse.error_description;
  if (
    objectResponse &&
    objectResponse.errors &&
    Array.isArray(objectResponse.errors) &&
    objectResponse.errors.length
  ) {
    const firstError = objectResponse.errors[0];
    if (firstError) {
      return firstError.errorMessage || firstError.errorCode;
    }
  } else if (objectResponse.message) {
    return objectResponse.message;
  } else if (objectResponse.msg) {
    return objectResponse.msg;
  }

  return null;
};

const processResponse = async (
  params: BaseApiParams,
  response: any,
  reprocessData: ReprocessData
) => {
  let message: string | undefined = undefined;
  const code = response.status;

  switch (code) {
    case 401:
      message = "Se ha producido un error. Verifique sus credenciales";
      console.error("Unauthorized [401]");
      params.unauthorizedCallback
        ? params.unauthorizedCallback()
        : unauthorizedCallback();
      break;

    case 403:
      let res = JSON.parse(await response.text());
      throw new Error(res.message);

    case 404:
      throw new Error("Se ha producido un error inesperado");

    case 405:
      console.error(`Unauthorized [${code}]`);
      params.unauthorizedCallback
        ? params.unauthorizedCallback()
        : unauthorizedCallback();
      break;

    case 422:
    case 400:
      const errorMessage =
        (await getErrorFromServiceResponse(response)) ||
        "Se ha producido un error inesperado";
      if (errorMessage) {
        throw new Error(errorMessage);
      } else {
        params.unauthorizedCallback
          ? params.unauthorizedCallback()
          : unauthorizedCallback();
      }
      break;

    case 409:
      const errorMessage409 = await getErrorFromServiceResponse(response);
      if (errorMessage409) {
        throw new Error(errorMessage409);
      } else {
        message = await response.text();
      }
      break;

    case 415:
    case 500:
      const errorMessage500 = await getErrorFromServiceResponse(response);
      if (errorMessage500) {
        throw new Error(errorMessage500);
      } else {
        message = await response.text();
      }
      break;
  }

  return message && message.replace(/['"]+/g, "");
};

export {
  apiGet,
  apiPost,
  apiPatch,
  apiDelete,
  apiPostDownload,
  genericRequest,
  apiPut,
  getHeadersWithoutContentType,
  getHeaders,
};
