import * as types from "./actionTypes";

export const setMetricsDisplay = (display: boolean) => {
  return {
    type: types.SET_METRICS_DISPLAY,
    payload: display,
  };
};

export const getMetricConfigDataAction = () => {
  return {
    type: types.GET_METRICS_CONFIG_DATA,
  };
};

export const getMetricsConfigDataFail = (error: any) => {
  return {
    type: types.GET_METRICS_CONFIG_DATA_FAIL,
    payload: error,
  };
};

export const getMetricsConfigDataSuccess = (response: any) => {
  return {
    type: types.GET_METRICS_CONFIG_DATA_SUCCESS,
    payload: response,
  };
};

export const getMetricsTypesDataAction = () => {
  return {
    type: types.GET_METRICS_TYPES_DATA,
  };
};

export const getMetricsTypesDataFail = (error: any) => {
  return {
    type: types.GET_METRICS_TYPES_DATA_FAIL,
    payload: error,
  };
};

export const getMetricsTypesDataSuccess = (response: any) => {
  return {
    type: types.GET_METRICS_TYPES_DATA_SUCCESS,
    payload: response,
  };
};

export const getMetricsZonesDataAction = () => {
  return {
    type: types.GET_METRICS_ZONES_DATA,
  };
};

export const getMetricsZonesDataFail = (error: any) => {
  return {
    type: types.GET_METRICS_ZONES_DATA_FAIL,
    payload: error,
  };
};

export const getMetricsZonesDataSuccess = (response: any) => {
  return {
    type: types.GET_METRICS_ZONES_DATA_SUCCESS,
    payload: response,
  };
};

export const getMetricsSpeciesDataAction = () => {
  return {
    type: types.GET_METRICS_SPECIES_DATA,
  };
};

export const getMetricsSpeciesDataFail = (error: any) => {
  return {
    type: types.GET_METRICS_SPECIES_DATA_FAIL,
    payload: error,
  };
};

export const getMetricsSpeciesDataSuccess = (response: any) => {
  return {
    type: types.GET_METRICS_SPECIES_DATA_SUCCESS,
    payload: response,
  };
};
