import { call, put, takeLatest } from "redux-saga/effects";
import { Endpoints } from "../../config/endpoints";
import { API_URL_IOF } from "../../config/general-config";
import { apiGet } from "../../shared/ApiService";
import { ApiResponse } from "../../shared/types";
import {
  cleanCreateOrUpdateResponseFail,
  cleanCreateOrUpdateResponseSuccess,
  cleanOrganizationDataFail,
  cleanOrganizationDataSucess,
  clearCountrysidesLotsFail,
  clearCountrysidesLotsSuccess,
  getAllCountriesFail,
  getAllCountriesSuccess,
  getOrganizationDataFail,
  getOrganizationDataSuccess,
  getOrganizationsListDataFail,
  getOrganizationsListDataSuccess,
  getCountryL0Success,
  getCountryL0Fail,
  getCountryL1Success,
  getCountryL1Fail,
  getCountryL2Success,
  getCountryL2Fail,
  getLocalitiesListDataAction,
  getLocalitiesListDataFail,
  getLocalitiesListDataSuccess,
} from "./actions";
import {
  CLEAN_CREATE_OR_UPDATE_RESPONSE,
  CLEAN_ORGANIZATION_DATA,
  CLEAR_LOTS_LIST,
  GET_ALL_COUNTRIES_ACTION,
  GET_ORGANIZATIONS_LIST_DATA,
  GET_ORGANIZATION_DATA,
  GET_COUNTRY_L0,
  GET_COUNTRY_L1,
  GET_COUNTRY_L2,
  GET_LOCALITY
} from "./actionTypes";

function* onGetOrganizationsListData() {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_IOF}/${Endpoints.API_IOF.ORGANIZATIONS}`,
        })
      );
    });
    yield put(getOrganizationsListDataSuccess(response));
  } catch (error) {
    yield put(getOrganizationsListDataFail(error));
  }
}

function* onGetCountryL0() {
  try {
    const response: any[] = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_IOF}/${Endpoints.API_IOF.COUNTRIES_L0}`,
        })
      );
    });
    yield put(getCountryL0Success(response));
  } catch (error) {
    yield put(getCountryL0Fail(error));
  }
}

function* onGetCountryL1(action: any) {
  try {
    const { countryL0Id } = action.payload;

    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_IOF}/${Endpoints.API_IOF.COUNTRIES_L1}/countryL0/${countryL0Id}`,
        })
      );
    });

    yield put(getCountryL1Success(response));
  } catch (error) {
    yield put(getCountryL1Fail(error));
  }
}

function* onGetCountryL2(action: any) {
  const { countryL1Id } = action.payload;
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_IOF}/${Endpoints.API_IOF.COUNTRIES_L2}/countryL1/${countryL1Id}`,
        })
      );
    });
    yield put(getCountryL2Success(response));
  } catch (error) {
    yield put(getCountryL2Fail(error));
  }
}

function* onGetLocalities(action: any) {
  const { countryL2Id } = action.payload;
  try {
    let response: any[] = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_IOF}/${Endpoints.API_IOF.LOCALITIES}/countryL2/${countryL2Id}`,
        })
      );
    });
    yield put(getLocalitiesListDataSuccess(response));
  } catch (error) {
    yield put(getLocalitiesListDataFail(error));
  }
}

function* onCleanOrUpdateResponseData() {
  try {
    yield call(() => {
      return Promise.resolve();
    });
    yield put(cleanCreateOrUpdateResponseSuccess());
  } catch (error) {
    yield put(cleanCreateOrUpdateResponseFail(error));
  }
}

function* onCleanOrganizationData() {
  try {
    yield call(() => {
      return Promise.resolve();
    });
    yield put(cleanOrganizationDataSucess());
  } catch (error) {
    yield put(cleanOrganizationDataFail(error));
  }
}

function* onGetOrganizationData(action: any) {
  try {
    const { org_id } = action;
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_IOF}/${Endpoints.API_IOF.ORGANIZATIONS}/${org_id}?active=true`,
        })
      );
    });
    yield put(getOrganizationDataSuccess(response));
  } catch (error) {
    yield put(getOrganizationDataFail(error));
  }
}

function* onClearLotsListData(payload: any) {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve();
    });
    yield put(clearCountrysidesLotsSuccess(response));
  } catch (error) {
    yield put(clearCountrysidesLotsFail(error));
  }
}

function* onGetAllCountriesData() {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({ url: `${API_URL_IOF}/${Endpoints.API_IOF.COUNTRIES}` })
      );
    });
    yield put(getAllCountriesSuccess(response));
  } catch (error) {
    yield put(getAllCountriesFail(error));
  }
}

function* OrganizationsSaga() {
  yield takeLatest(GET_ORGANIZATIONS_LIST_DATA, onGetOrganizationsListData);
  yield takeLatest(
    CLEAN_CREATE_OR_UPDATE_RESPONSE,
    onCleanOrUpdateResponseData
  );
  yield takeLatest(CLEAN_ORGANIZATION_DATA, onCleanOrganizationData);
  yield takeLatest(GET_COUNTRY_L0, onGetCountryL0);
  yield takeLatest(GET_COUNTRY_L1, onGetCountryL1);
  yield takeLatest(GET_COUNTRY_L2, onGetCountryL2);
  yield takeLatest(GET_LOCALITY, onGetLocalities);
  yield takeLatest(GET_ALL_COUNTRIES_ACTION, onGetAllCountriesData);
  yield takeLatest(GET_ORGANIZATION_DATA, onGetOrganizationData);

  yield takeLatest(CLEAR_LOTS_LIST, onClearLotsListData);
}

export default OrganizationsSaga;
